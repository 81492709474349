import { Component, OnDestroy, OnInit } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';
import { GeneralStoreFacade } from '../../bbo-store/store/services/general-store-facade.service';
import { Router } from '@angular/router';
import { CreditCard } from 'src/app/payment';
import { BillingAddressRequest } from 'src/app/billing-address/infrastructure/billing-address.interface';
import { Subject, Subscription, combineLatest, filter, map, take, takeUntil, tap, zip } from 'rxjs';
import { Nullable, Option, isNotNil } from 'src/app/@core';
import { SubscribeBboPlusUseCaseError } from '../domain/subscribe-bbo-plus.usecase';

@Component({
    selector: 'bbo-plus-subscribe',
    styleUrls: ['./bbo-plus-subscribe.component.scss'],
    templateUrl: 'bbo-plus-subscribe.component.html'
})

export class BboPlusSubscribeComponent implements OnInit, OnDestroy {

    xlationCodes = XlationCodes;

    savedCreditCard$ = this.generalStoreFacade.storedCreditCard$;
    billingAddress$ = this.generalStoreFacade.billingAddress$;

    isCompleted = false;

    error: Option<Error>;

    private readonly unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private generalStoreFacade: GeneralStoreFacade
    ) { }

    ngOnInit(): void {
        this.generalStoreFacade.bboPlusResult$
            .pipe(
                takeUntil(this.unsubscribeSubject),
                filter(isNotNil),
                filter((bboPlus) => bboPlus.autoRenew)
            )
        .subscribe((bboPlus) => {
            this.generalStoreFacade.updateGeneralStore({ bboPlus });
            this.isCompleted = true;
            this.error = null;
        });

        this.generalStoreFacade.bboPlusError$
            .pipe(
                takeUntil(this.unsubscribeSubject),
                filter(isNotNil),
                filter((result: any) =>
                    result.error.code === SubscribeBboPlusUseCaseError.ALREADY_BBO_PLUS || result.error.code === SubscribeBboPlusUseCaseError.GENERIC_ERROR
                ),
                map((result: any) => result.error)
            )
        .subscribe((error) => {
            this.isCompleted = false;
            this.error = error;
        });

        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    updateCard(data: { creditCard: CreditCard; billingAddress: BillingAddressRequest }): void {
        this.generalStoreFacade.updateSavedCreditCard(data.creditCard);
        this.generalStoreFacade.updateSavedBillingAddress(data.billingAddress);
    }

    subscribeToBboPlus(): void {
        this.generalStoreFacade.subscribeBboPlus();
    }

    cancel(): void {
        this.router.navigateByUrl("/bbo-plus");
    }

    closeErrorCard(): void {
        this.error = null;
        this.router.navigateByUrl("/bbo-plus");
    }
}