import {ActionReducerMap} from "@ngrx/store";
import {generalStoreReducer, GeneralStoreReducerState} from "./general-store.reducer";
import {savedCreditCardReducer, SavedCreditCardState} from "./saved-credit-card.reducer";
import {savedBillingAddressReducer, SavedBillingAddressState} from "./saved-billing-address.recuder";
import {currentAutoRefillPlanReducer, CurrentAutoRefillPlanState} from "./current-auto-refill-plan.reducer";
import {currentAccountBalanceReducer, CurrentAccountBalanceState} from "./current-account-balance.reducer";
import {bboPlusReducer, BboPlusState} from "./bbo-plus.reducer";

export interface GeneralStoreState {
    readonly generalStore: GeneralStoreReducerState;
    readonly savedCreditCard: SavedCreditCardState;
    readonly savedBillingAddress: SavedBillingAddressState;
    readonly currentAutoRefillPlan: CurrentAutoRefillPlanState;
    readonly currentAccountBalance: CurrentAccountBalanceState;
    readonly bboPlus: BboPlusState;
}

export const GENERAL_STORE_NAME = 'general-store';

export interface GeneralStoreStateSlice {
    readonly [GENERAL_STORE_NAME]: GeneralStoreState;
}

export const generalStoreReducers: ActionReducerMap<GeneralStoreState, any> = {
    generalStore: generalStoreReducer,
    savedCreditCard: savedCreditCardReducer,
    savedBillingAddress: savedBillingAddressReducer,
    currentAutoRefillPlan: currentAutoRefillPlanReducer,
    currentAccountBalance: currentAccountBalanceReducer,
    bboPlus: bboPlusReducer
}
