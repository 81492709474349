import {Injectable} from "@angular/core";
import {GeneralStoreState} from "../reducers";
import {Store} from "@ngrx/store";
import {
    CurrentAccountBalance,
    currentAccountBalanceActions,
    currentAutoRefillPlanActions,
    GeneralStore,
    generalStoreActions,
    savedBillingAddressActions,
    savedCreditCardActions,
    bboPlusActions
} from "../actions";
import {generalStoreSelectors} from "../selectors";
import {CreditCard} from "../../../payment";
import {BillingAddressRequest} from "../../../billing-address/infrastructure/billing-address.interface";

@Injectable({
    providedIn: "root"
})
export class GeneralStoreFacade {

    public readonly flowState$ = this.store.select(generalStoreSelectors.data);
    public readonly storedCreditCard$ = this.store.select(generalStoreSelectors.selectSavedCreditCardStateSelectors.data);
    public readonly currentAutoRefillPlan$ = this.store.select(generalStoreSelectors.selectedCurrentAutoRefillPlan);
    public readonly currentBalance$ = this.store.select(generalStoreSelectors.selectCurrentAccountBalanceAmount);
    public readonly loading$ = this.store.select(generalStoreSelectors.loading);
    public readonly savedCreditCardLoading$ = this.store.select(generalStoreSelectors.selectSavedCreditCardStateSelectors.loading);
    public readonly billingAddress$ = this.store.select(generalStoreSelectors.selectedBillingAddress);
    public readonly bboPlus$ = this.store.select(generalStoreSelectors.selectBboPlusSelectors.data);
    public readonly bboPlusLoading$ = this.store.select(generalStoreSelectors.selectBboPlusSelectors.loading);
    public readonly bboPlusLoaded$ = this.store.select(generalStoreSelectors.selectBboPlusSelectors.loaded);
    public readonly bboPlusError$ = this.store.select(generalStoreSelectors.selectBboPlusSelectors.error);
    public readonly bboPlusResult$ = this.store.select(generalStoreSelectors.selectBboPlusSelectors.result);

    constructor(private readonly store: Store<GeneralStoreState>) {
    }

    updateGeneralStore(patch: Partial<GeneralStore>) {
        this.store.dispatch(generalStoreActions.update({payload: {patch}}));
    }

    updateSavedCreditCard(patch: Partial<CreditCard>) {
        this.store.dispatch(savedCreditCardActions.update({payload: {patch}}))
    }

    replaceSavedCreditCard(payload: CreditCard) {
        this.store.dispatch(savedCreditCardActions.replace({payload}))
    }

    loadSavedCreditCard() {
        this.store.dispatch(savedCreditCardActions.load({}))
    }

    loadBboPlus() {
        this.store.dispatch(bboPlusActions.load({}))
    }

    unsubscribeBboPlus() {
        this.store.dispatch(bboPlusActions.unSubscribe())
    }

    subscribeBboPlus() {
        this.store.dispatch(bboPlusActions.subscribe())
    }

    loadSavedBillingAddress() {
        this.store.dispatch(savedBillingAddressActions.load({}));
    }

    deleteCurrentAutoRefillPlan() {
        this.store.dispatch(currentAutoRefillPlanActions.remove());
    }

    loadCurrentAutoRefillPlan() {
        this.store.dispatch(currentAutoRefillPlanActions.load({}));
    }

    loadCurrentAccountBalance(){
        this.store.dispatch(currentAccountBalanceActions.load({}));
    }

    updateCurrentAccountBalance(patch: Partial<CurrentAccountBalance>){
        this.store.dispatch(currentAccountBalanceActions.update({payload: {patch}}));
    }

    updateSavedBillingAddress(patch: Partial<BillingAddressRequest>) {
        this.store.dispatch(savedBillingAddressActions.update({payload: {patch}}))
    }

    deleteCreditCard() {
        this.store.dispatch(savedCreditCardActions.remove());
    }

    resetFlow() {
        this.store.dispatch(generalStoreActions.reset());
    }

    resetCreditCard() {
        this.store.dispatch(savedCreditCardActions.reset());
    }

    resetSavedBillingAddress() {
        this.store.dispatch(savedBillingAddressActions.reset());
    }

    resetCurrentAutoRefillPlan() {
        this.store.dispatch(currentAutoRefillPlanActions.reset());
    }

    resetBboPlus() {
        this.store.dispatch(bboPlusActions.reset());
    }

    submit() {
        this.store.dispatch(generalStoreActions.submit());
    }
}
