<mat-card class="checkout-details-card">
    <mat-card-content class="bbo-medium-card">
        <ng-container *ngIf="paymentMethod">
            <span class="bbo-headline-3 bbo-headline-2-md-up">{{xlationCodes.payWith | translate:[paymentMethod]}}</span>
            <div class="desktop-hidden bbo-headline-6">{{xlationCodes.selectedMethod | translate}}</div>
            <div class="other-card-button">
                <button
                    *ngIf="enablePayWithOtherCard"
                    class="mobile-hidden bbo-button bbo-small-button"
                    mat-button color="primary"
                    (click)="openOneTimeCardModal()"
                >
                    <mat-icon>add_card</mat-icon>
                    {{xlationCodes.useDifferentCreditCard | translate}}
                </button>
            </div>
            <bbo-payment-item [creditCard]="creditCard" [type]="paymentMethod" (addPaymentItemClicked)="openAddCardModal()">
                <button mat-button
                    *ngIf="enableChangePaymentMethodButton"
                    class="bbo-button"
                    color="primary"
                    (click)="changePaymentMethod()">
                    {{xlationCodes.change | translate}}
                </button>
                <button *ngIf="creditCard" mat-button class="bbo-button mobile-hidden" color="primary" (click)="openEditCardModal()">{{xlationCodes.edit | translate}}</button>
            </bbo-payment-item>
            <div *ngIf="enablePaymentMethodEdit" class="buttons-container desktop-hidden">
                <button mat-button class="bbo-button bbo-small-button" color="primary" (click)="openEditCardModal()">
                    <mat-icon>edit</mat-icon>
                    {{xlationCodes.editCard | translate}}
                </button>
                <button class="add-credit-card-button bbo-button bbo-small-button"
                        (click)="openOneTimeCardModal()" mat-button color="primary">
                    <mat-icon>add_card</mat-icon>
                    {{xlationCodes.useDifferentCreditCard | translate}}
                </button>
            </div>
            <ng-content select="[order-details]"></ng-content>
            <div class="terms-and-conditions text-center bbo-body-2">
                <span>{{xlationCodes.agreeTermsAndConditions | translate}}</span>
                <span class="link" (click)="openLink('https://bridgebase.com/privacy')">Privacy Policy</span>
                <span>{{xlationCodes.and | translate}}</span>
                <span class="link" (click)="openLink('https://news.bridgebase.com/terms/')">Terms of Service</span>
            </div>
            <div class="actions">
                <ng-content select="[actions]"></ng-content>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
