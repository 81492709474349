<bbo-checkout
    (updateCard)="updateCard.emit($event)"
    [creditCard]="savedCreditCard"
    [billingAddress]="billingAddress"
    [enablePaymentMethodEdit]="false"
    [enableSaveCardOnAdd]="true"
    [enableChangePaymentMethodButton]="false"
    paymentMethod="credit-card">
    <ng-container order-details>
        <bbo-plus-order-details class="desktop-hidden"></bbo-plus-order-details>
    </ng-container>
    <ng-container actions>
        <button mat-button class="bbo-button" (click)="cancel.emit()">{{xlationCodes.cancel | translate}}</button>
        <button mat-flat-button class="bbo-button width-100" color="purple" [disabled]="!savedCreditCard" (click)="subscribeToBboPlus.emit()">{{xlationCodes.subscribe | translate}}</button>
    </ng-container>
</bbo-checkout>
<div class="bbo-plus-details-container mobile-hidden">
    <mat-card>
        <mat-card-content class="bbo-medium-card">
            <bbo-plus-order-details></bbo-plus-order-details>
        </mat-card-content>
    </mat-card>
</div>