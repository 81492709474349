import { Component, OnDestroy } from '@angular/core';
import { GeneralStoreFacade } from 'src/app/bbo-store/store';
import { BboPlusGetResponse } from '../infrastructure/bbo-plus.interface';
import { Nullable, isNotNil } from 'src/app/@core';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'bbo-plus',
    templateUrl: './bbo-plus.component.html',
    styleUrls: ['./bbo-plus.component.scss']
})
export class BboPlusComponent implements OnDestroy {

    bboPlus: Nullable<BboPlusGetResponse> = null;
    private readonly unsubscribeSubject: Subject<void> = new Subject<void>();

    constructor(
        generalStoreFacade: GeneralStoreFacade
    ) {
        generalStoreFacade.bboPlus$
            .pipe(
                filter(isNotNil),
                takeUntil(this.unsubscribeSubject)
            )
        .subscribe((bboPlus) => {
            this.bboPlus = bboPlus;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
