import { Component, OnInit } from '@angular/core';
import { GeneralStoreFacade } from '../bbo-store/store';

@Component({
    selector: 'bbo-plus-home',
    styleUrls: ["./bbo-plus-home.component.scss"],
    templateUrl: 'bbo-plus-home.component.html'
})

export class BboPlusHomeComponent implements OnInit {

    constructor(
        private generalStoreFacade: GeneralStoreFacade,
    ) { }

    ngOnInit() {
        this.generalStoreFacade.loadBboPlus();
        this.generalStoreFacade.loadSavedCreditCard();
        this.generalStoreFacade.loadSavedBillingAddress();
    }
}