
export class XlationCodes {
    public static signInToBuy = "BS000";
    public static userName = "BS001";
    public static password = "BS002";
    public static forgetPassword = "BS003";
    public static signIn = "BS004";
    public static loginFormError = "BS005";
    public static loginFormWrongCombinaisonError = "BS006"
    public static resetPasswordDescription = "BS007";
    public static resetPasswordFirstStep = "BS008";
    public static resetPasswordSecondStep = "BS009";
    public static close = "BS010";
    public static hidePassword = "BS011";

    public static welcomeMessage = "BS012";
    public static home = "BS013";
    public static buyBB$ = "BS014";
    public static autoRefill = "BS015";
    public static paymentHistory = "BS016";
    public static paymentMethods = "BS017";
    public static yourCurrentBalance = "BS018";
    public static signOut = "BS019";
    public static welcome = "BS020";
    public static oneOffPurchase = "BS021";
    public static buyBB$Description = "BS022";
    public static buyBB$DescriptionShort = "BS165";
    public static subscribeToAutoRefillHint = "BS023";
    public static autoRefillDescription = "BS024";
    public static subscribedTo = "BS025";
    public static taxes = "BS026";
    public static billedCondition = "BS027";
    public static outdatedPlanHint = "BS028";
    public static editPlan = "BS029";
    public static choosePlan = "BS030";

    public static option = "BS031";
    public static payment = "BS032";
    public static checkout = "BS033";
    public static noCurrentAutoRefillPlan = "BS034";
    public static payNow = "BS035";
    public static chooseAPlan = "BS036";
    public static subscribeNowHint = "BS037";
    public static ourRecommendation = "BS038";

    public static paymentMethod = "BS039";
    public static creditCard = "BS040";
    public static useDifferentCreditCard = "BS041";
    public static edit = "BS042";
    public static editCard = "BS043";
    public static otherWaysToPay = "BS044";
    public static continueToCheckout = "BS045";
    public static endingIn = "BS046";
    public static expiring = "BS047";
    public static noCreditCardSaved = "BS048";
    public static add = "BS049";

    public static orderDetails = "BS050";
    public static currentBalance = "BS051"
    public static order = "BS052"
    public static newBalance = "BS053"
    public static subtotal = "BS054"
    public static total = "BS055"

    public static payWith = "BS056";
    public static selectedMethod = "BS057";
    public static change = "BS058";
    public static agreeTermsAndConditions = "BS059";
    public static cancel = "BS060";
    public static orderComplete = "BS061";
    public static sendingConfirmationEmail = "BS062";
    public static creditedSoon = "BS063";
    public static subscribeToAutoRefill = "BS064";
    public static purchaseCompleteAutoRefillAdvise = "BS065";
    public static goToBBO = "BS066";
    public static returnToBBO = "BS067";

    public static orderCancelled = "BS068";
    public static paymentFailed = "BS069";
    public static cantValidatePayment = "BS070";
    public static pleaseVerifyPaymentInformations = "BS071";
    public static tryAgain = "BS072";

    public static editCreditCardDetails = "BS073";
    public static payWithCreditCard = "BS074";
    public static newPaymentCard = "BS075";
    public static saveAndReturn = "BS076";
    public static creditCardFormCreationCriticalError = "BS077";
    public static billingAddress = "BS078";
    public static creditCardFormError = "BS079";
    public static billingAddressFormError = "BS080";
    public static formInvalidCardNumberError = "BS081";
    public static formMissingDigitsError = "BS082";
    public static formRequiredError = "BS083";
    public static cardType = "BS084";
    public static holderFirstName = "BS085";
    public static holderFirstNamePlaceholder = "BS086";
    public static holderLastName = "BS087";
    public static holderLastNamePlaceholder = "BS088";
    public static expirationDate = "BS089";
    public static expirationYearPlaceholder = "BS090";
    public static formMaxlengthError = "BS091";
    public static expirationMonth = "BS092";
    public static expirationMonthPlaceholder = "BS093";
    public static cardNumber = "BS094";
    public static cardNumberPlaceholder = "BS095";
    public static securityCodePlaceholder = "BS096";
    public static email = "BS097";
    public static emailPlaceholder = "BS098";
    public static address = "BS099";
    public static addressPlaceholder = "BS100";
    public static address2 = "BS101";
    public static address2Placeholder = "BS102";
    public static country = "BS103";
    public static countryPlaceholder = "BS104";
    public static state = "BS105";
    public static statePlaceholder = "BS106";
    public static city = "BS107";
    public static cityPlaceholder = "BS108";
    public static zipCode = "BS109";
    public static zipCodePlaceholder = "BS110";
    public static subscription = "BS111";
    public static cancelPlan = "BS112";
    public static currentPlan = "BS113";
    public static subscriptionDetails = "BS114";
    public static subscriptionDetailsDescription = "BS115";
    public static subscriptionDetailsEstimationInformations = "BS116";
    public static subscribe = "BS117";
    public static subscriptionComplete = "BS118";
    public static subscriptionActivationCondition = "BS119";
    public static type = "BS120";
    public static date = "BS121";
    public static method = "BS122";
    public static status = "BS123";
    public static amount = "BS124";
    public static paid = "BS125";
    public static failed = "BS126";
    public static paymentDetails = "BS127";
    public static downloadReceipt = "BS128";
    public static deleteThisCreditCard = "BS129";
    public static calculatedOnCheckout = "BS130";
    public static cancelSubscription = "BS132";
    public static cancelSubscriptionMessage = "BS133";
    public static cancelSubscriptionQuestion = "BS134";
    public static cancelMySubscription = "BS135";
    public static keepMeSubscibed = "BS136";
    public static deletePaymentCard = "BS138";
    public static deletePaymentCardMessage = "BS139";
    public static deletePaymentCardQuestion = "BS140";
    public static deleteMyCard = "BS141";
    public static keepIt = "BS142";
    public static cards = "BS144";
    public static and = "BS145";
    public static cvvHint = "BS148";
    public static saveCardForLaterPurchases = "BS149";
    public static expiredCreditCardExpirationDateError = "BS150";
    public static badEmailFormat = "BS151";
    public static zipCodeMaxlengthError = "BS152";
    public static exitPurchase = "BS153";
    public static saveMyDataMessage = "BS154";
    public static abandonProgress = "BS155";
    public static saveForLater = "BS156";
    public static continue = "BS157";
    public static autoRefillAmountDescription = "BS158";
    public static below = "BS159";
    public static payment_method_error_occurred = "BS160";
    public static unexpected_error_occurred = "BS161";

    public static bboPlus = "BS162";
    public static discoverBboPlus = "BS163";
    public static discoverBboPlusButtonLabel = "BS164";
    public static basic = "BS167";
    public static free = "BS168";
    public static bboPlusFreePlanDescription = "BS169";
    public static backToBasic = "BS170";
    public static perMonth = "BS171";
    public static bboPlusOfferCardDescription = "BS172";
    public static advertFree = "BS173";
    public static videoChat = "BS174";
    public static customRobots = "BS175";
    public static bridgerama = "BS176";
    public static bboPlusLounge = "BS177";
    public static joinBboPlusCommunity = "BS178";
    public static cancelBboPlusConfirmationMessage = "BS179";
    public static cancelBboPlus = "BS180";
    public static notNow = "BS181";
    public static yourBboMembership = "BS182";
    public static bboPlusMembershipCanceledMessage = "BS184";
    public static active = "BS185";
    public static expireOn = "BS186";
    public static renewSubscribtion = "BS187";
    public static cancelMembership = "BS188";
    public static everyMonth = "BS189";
    public static advertFreeDescription = "BS190";
    public static videoChatDescription = "BS191";
    public static customRobotsDescription = "BS192";
    public static bridgeramaDescription = "BS193";
    public static bboPlusLoungeDescription = "BS194";
    public static bboPlusSubscriptionDetailsDescription = "BS195";
    public static bboPlusTaxes = "BS196";
    public static welcomeOnBoard = "BS197";
    public static bboPlusWelcomeDescription = "BS198";
    public static orderSummary = "BS199";
    public static bboPlusSubscription = "BS200";
    public static bboPlusOrderPricePerMonth = "BS201";
    public static noTaxesValue = "BS202";
    public static errorOnSubscription = "BS203";
    public static advancedRobots = "BS204";
    public static advancedRobotsDescription = "BS205";
    public static joinBboPlus = "BS206";
    public static bboPlusBenefits = "BS207";
    public static bboSupportDescription = "BS208";
    public static bboSupportTitle = "BS209";
    public static bboSupport = "BS210";
    public static returnToHome = "BS211";
}
