import { Component, Input } from '@angular/core';
import { XlationCodes } from '../../shared/translations/xlation.codes';
import { Nullable } from 'src/app/@core';

@Component({
    selector: 'bbo-bbo-plus-tile',
    templateUrl: './bbo-plus-tile.component.html',
    styleUrls: ['./bbo-plus-tile.component.scss']
})
export class BboPlusTileComponent {
    @Input() amount: Nullable<number> = null;
    xlationCodes = XlationCodes;
}
