import {InjectionToken} from '@angular/core';
import {Observable} from "rxjs";

export const BBO_PLUS_REPOSITORY = new InjectionToken<IBboPlusRepository>("BBO_PLUS_REPOSITORY");

export interface BboPlusGetResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

export interface BboPlusUnsubscribeResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

export interface BboPlusSubscribeResponse {
    owner: {
        id: number
    },
    active: boolean,
    autoRenew: boolean,
    billingType: string,
    expiresAt: Date
}

export interface IBboPlusRepository {
    getBboPlus(uid: string): Observable<BboPlusGetResponse>;
    unsubscribeBboPlus(uid: string): Observable<BboPlusUnsubscribeResponse>;
    subscribeBboPlus(uid: string): Observable<BboPlusSubscribeResponse>;
}
