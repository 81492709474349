import { Component } from '@angular/core';
import { XlationCodes } from 'src/app/shared/translations/xlation.codes';

@Component({
  selector: 'bbo-plus-order-details',
  templateUrl: './bbo-plus-order-details.component.html',
  styleUrls: ['./bbo-plus-order-details.component.scss']
})
export class BboPlusOrderDetailsComponent {

    xlationCodes = XlationCodes;

}
