<div class="title bbo-headline-3 headline-2-md-up text-center" [innerHTML]="xlationCodes.joinBboPlusCommunity | translate"></div>
<div class="bbo-plus-cards-container bbo-large-line-height">
    <mat-card>
        <mat-card-content class="bbo-plus-card bbo-medium-card">
            <div class="card-content">
                <div class="card-header">
                    <div class="color-accent-darker font-weight-bold">{{xlationCodes.bboPlus | translate}}</div>
                    <div class="price-container">
                        <!-- actually hard coded, in a near futur bbo+ prices will probably come from the backend -->
                        <div class="bbo-headline-4 font-weight-light">7.99 BB$</div>
                        <div class="color-gray">{{xlationCodes.perMonth | translate}}</div>
                    </div>
                </div>
                <div class="card-description">
                    <div class="width-100 divider-after"></div>
                    <div class="description text-center">
                        {{xlationCodes.bboPlusOfferCardDescription | translate}}
                    </div>
                </div>
                <div class="card-advantages-container">
                    <div class="bbo-body-2 text-center color-purple font-weight-semibold bbo-large-line-height" *ngFor="let advantage of advantagesList">
                        {{advantage.titleTranslationKey | translate}}
                    </div>
                </div>
            </div>
            <button mat-flat-button class="bbo-button width-100" color="purple" routerLink="/bbo-plus/subscribe">{{xlationCodes.joinBboPlus | translate}}</button>
        </mat-card-content>
    </mat-card>
    <div class="advantages-container mobile-hidden">
        <div class="font-weight-semibold bbo-large-line-height">{{xlationCodes.bboPlusBenefits | translate}}</div>
        <div class="advantage" *ngFor="let advantage of advantagesList">
            <div class="bbo-headline-6 color-purple bbo-large-line-height font-weight-light">{{advantage.titleTranslationKey | translate}}</div>
            <div class="bbo-body-2 bbo-large-line-height">{{advantage.descriptionTranslationKey | translate}}</div>
        </div>
    </div>
</div>