import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { BboPlusGetResponse, BboPlusSubscribeResponse, BboPlusUnsubscribeResponse, IBboPlusRepository } from './bbo-plus.interface';

@Injectable()
export class BboPlusRepository implements IBboPlusRepository {

    constructor(
        private http: HttpClient
    ) {}

    getBboPlus(uid: string): Observable<BboPlusGetResponse> {
        return this.http.get<BboPlusGetResponse>(
            `${environment.apiURL}/users/${uid}/bbo-plus`,
            {
                headers: new HttpHeaders().append("Accept", "application/json, text/plain")
            }
        );
    }

    unsubscribeBboPlus(uid: string): Observable<BboPlusUnsubscribeResponse> {
        return this.http.patch<BboPlusUnsubscribeResponse>(
            `${environment.apiURL}/users/${uid}/bbo-plus/unsubscribe`, {},
            {headers:new HttpHeaders({'Content-Type':'application/merge-patch+json', "Accept": "application/json, text/plain"})}
        );
    }

    subscribeBboPlus(uid: string): Observable<BboPlusSubscribeResponse> {
        return this.http.post<BboPlusSubscribeResponse>(
            `${environment.apiURL}/users/${uid}/bbo-plus/subscribe`, {},
            {headers:new HttpHeaders({'Content-Type':'application/merge-patch+json', "Accept": "application/json, text/plain"})}
        );
    }
}
