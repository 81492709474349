<mat-card class="purchase-failure-card">
    <mat-card-content class="bbo-medium-card">
        <div class="card-header">
            <div class="bbo-headline-4 bbo-headline-3-sm-up bbo-headline-2-md-up">{{xlationCodes.unexpected_error_occurred | translate}}</div>
        </div>
        <div class="card-content">
            <div class="failure-header">
                <div class="failure-icon mobile-hidden">
                    <mat-icon fontIcon="block" class="badge"></mat-icon>
                </div>
                <div class="bbo-headline-4 bbo-headline-3-sm-up bbo-headline-2-md-up">{{data.errorMessage | translate}}</div>
            </div>
<!--            <div class="failure-message bbo-typography color-gray">-->
<!--                <div class="description bbo-body-2 color-gray">{{data.errorMessage}}</div>-->
<!--            </div>-->
            <button mat-flat-button class="bbo-button" color="primary" mat-dialog-close>Close</button>
        </div>
    </mat-card-content>
</mat-card>
